import React from "react"
import styled from "@emotion/styled"

const StyledDescription = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 5rem;
  .content-wrapper {
    display: grid;
    grid-template-columns: 8fr 2fr;
    .content {
      padding: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 6vw;
      font-size: 1.2vw;
      line-height: 2vw;
    }
  }
`

const Description = () => {
  return (
    <StyledDescription>
      <div className="empty-space"></div>
      <div className="content-wrapper">
        <div className="content">
          <div className="left">
            <p>
              BMR Labels is a company operating under the ISO 9001
              certification, meaning that the quality of our service is always
              kept to a high standard.
            </p>
            <p>
              Our standards are preserved through the commitment and pride of
              our workers who possess decades-long experience in the printing
              business as well as through our industry-standard flexo printing
              machines from Gallus Germany.
            </p>
          </div>
          <div className="right">
            <p>
              We get that a lot of time, effort (and funds) went into creating
              your product and designing your label. That’s why we’re here to
              help guide you through the final step of printing it with the help
              of our skilled individuals and quality machines, so that you can
              rest assured your label is in the right hands.{" "}
            </p>
            <p>
              Your journey is almost done, and we’ll help you reach the end.
            </p>
          </div>
        </div>
      </div>
    </StyledDescription>
  )
}

export default Description
