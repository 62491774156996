import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledVision = styled.div`
  .vision-wrapper {
    display: grid;
    grid-template-columns: 1fr 4fr;
    margin-top: 4vw;
    .content-wrapper {
      display: grid;
      grid-template-columns: 8fr 2fr;
      .content {
        display: grid;
        grid-template-columns: 2fr 6fr;
        padding-left: 2rem;
        margin-bottom: 3vw;
        .vision-heading {
          font-size: 1.7vw;
          color: black;
          font-weight: 700;
        }
        .vision-subheading {
          color: black;
          font-weight: 400;
          font-size: 1.7vw;
          .medium {
            font-weight: 600;
          }
        }
      }
    }
  }
`

const BgImg = styled(Img)`
  position: absolute;
  width: 100%;
  height: 60vh;
  z-index: 0;
  cursor: pointer;
  overflow: hidden;
  img {
    object-fit: cover !important; // or whatever
    object-position: 0% 35% !important; // or whatever
  }
`

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "about-vision-mission.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <BgImg fluid={data.coverImage.childImageSharp.fluid} />
}

const Vision = () => {
  return (
    <StyledVision>
      <div className="vision-wrapper">
        <div className="empty-space"></div>
        <div className="content-wrapper">
          <div className="content">
            <h1 className="vision-heading">VISION</h1>
            <h1 className="vision-subheading">
              To be the most{" "}
              <span className="medium">
                reliable and trustworthy label printing company in Indonesia.
              </span>
            </h1>
          </div>
        </div>
      </div>
      <Image />
    </StyledVision>
  )
}

export default Vision
