import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import accurateImage from "../../../images/quality-control/accurate-quality-control-mobile.svg"
import preciseImage from "../../../images/quality-control/precise-quality-control-mobile.svg"
import aboutSplash from "../../../images/about-splash-1.jpg"

import Services from "../../../components/Home/ServicesMobile"

const StyledAbout = styled.div`
  font-size: 16px;
  color: black;
  .heading {
    &.about {
      font-weight: 400;
    }
    &.vision {
      font-size: 1.5rem;
    }
    &.mission {
      font-size: 1.5rem;
    }
  }
  .padded {
    padding: 0 1rem;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;
    .content-splash {
      padding-top: 2rem;
      padding-bottom: 1rem;
      h1 {
        z-index: 2;
      }
    }

    &.values {
      background-color: black;
      color: white;
      padding-top: 2rem;
      padding-bottom: 1rem;
    }

    &.capabilities {
      padding-top: 1rem;
      .heading {
        font-weight: 400;
      }
      p {
        font-family: "Akzidenz Grotesk Extended";
        font-weight: 600;
      }
      ul {
        list-style: square;
      }
    }
    &.workplace {
      background-color: lightgrey;
      padding-top: 2rem;
      padding-bottom: 1rem;
      margin-bottom: 0;
      .heading {
        font-weight: 400;
      }
      .subheading {
        font-family: "Akzidenz Grotesk Extended";
        font-size: 1.2rem;
        font-weight: 600;
      }
      .description {
      }
    }
    &.quality-control {
      margin-top: 3rem;
      .heading {
        font-weight: 400;
        font-size: 2rem;
        margin-bottom: 4rem;
      }
      .subheading {
        font-family: "Akzidenz Grotesk Extended";
        font-weight: 400;
        font-size: 1.25rem;
        text-align: center;
      }
      .description {
        font-family: "Akzidenz Grotesk Extended";
        font-weight: 600;
        font-size: 1.25rem;
        text-align: center;
      }
    }

    .bg-img {
      position: absolute;
      left: 0;
      top: 0;
      width: auto;
      height: 100%;
      opacity: 0.4;
      z-index: -1;
    }
    .active {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .vision-image {
    margin-bottom: 2rem;
  }
  .capabilities-image {
  }
  .workplace-image {
    margin-bottom: 2rem;
  }
  .extended {
    font-family: "Akzidenz Grotesk Extended";
  }
`

const BgImg = styled(Img)`
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: 0;
  cursor: pointer;
  overflow: hidden;
  img {
    object-fit: cover !important; // or whatever
    object-position: 0% 35% !important; // or whatever
  }
`

const Image = ({ n }) => {
  const data = useStaticQuery(graphql`
    query {
      visionImage: file(relativePath: { eq: "about-vision-mission.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      capabilitiesImage: file(relativePath: { eq: "about-capabilities.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workplaceImage: file(relativePath: { eq: "about-workplace.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      honestyImage: file(relativePath: { eq: "values/values-honesty.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transparencyImage: file(
        relativePath: { eq: "values/values-transparency.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      committedImage: file(
        relativePath: { eq: "values/values-committed.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      consistencyImage: file(
        relativePath: { eq: "values/values-consistency.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  switch (n) {
    case 1:
      return <BgImg fluid={data.visionImage.childImageSharp.fluid} />
    case 2:
      return <BgImg fluid={data.capabilitiesImage.childImageSharp.fluid} />
    case 3:
      return <BgImg fluid={data.workplaceImage.childImageSharp.fluid} />
    case 4:
      return <BgImg fluid={data.honestyImage.childImageSharp.fluid} />
    case 5:
      return <BgImg fluid={data.transparencyImage.childImageSharp.fluid} />
    case 6:
      return <BgImg fluid={data.committedImage.childImageSharp.fluid} />
    case 7:
      return <BgImg fluid={data.consistencyImage.childImageSharp.fluid} />
  }
}

const About = () => {
  const [selectedValue, setSelectedValue] = useState(4)

  return (
    <StyledAbout>
      <div>
        <div className="padded">
          <div className="content-splash">
            <h1 className="heading about">About Us</h1>
            <p>
              Thank you for taking the time to read about us. We are a company
              specializing in printing labels for product packaging. If you are
              in the F&B, HPC, Pharmaceutical or any other industry that needs
              labels printed, there is definitely a way for us to help you with
              your labelling needs.
            </p>
          </div>
          <img className="bg-img" src={aboutSplash} />
        </div>
        <div className="padded">
          <p>
            BMR Labels is a company operating under the ISO 9001 certification,
            meaning that the quality of our service is always kept to a high
            standard.
          </p>
        </div>
        <div className="padded">
          <p>
            Our standards are preserved through the commitment and pride of our
            workers who possess decades-long experience in the printing business
            as well as through our industry-standard flexo printing machines
            from Gallus Germany.
          </p>
          <p>
            We get that a lot of time, effort (and funds) went into creating
            your product and designing your label. That’s why we’re here to help
            guide you through the final step of printing it with the help of our
            skilled individuals and quality machines, so that you can rest
            assured your label is in the right hands.
          </p>
          <p>Your journey is almost done, and we’ll help you reach the end.</p>
        </div>
        <div className="padded">
          <h1 className="heading vision">VISION</h1>
          <p className="extended">
            To be the most reliable and trustworthy label printing company in
            Indonesia.
          </p>
        </div>
        <div className="vision-image">
          <Image n={1} />
        </div>
        <div className="padded">
          <h1 className="heading mission">MISSION</h1>
          <p className="extended">
            To provide labels of excellence quality for all of our customers
            needs.
          </p>
          <p className="extended">
            To provide solutions for our customers through ; design inputs, on
            time delivery, competitive pricing, and excellent customer service.
          </p>
        </div>
        <div className="padded values">
          <h1>Values</h1>
          <p>Our basic and fundamental principles in Business:</p>
          <ul>
            <li
              className={`${selectedValue === 4 ? "active" : ""}`}
              onClick={() => setSelectedValue(4)}
            >
              Honesty
            </li>
            <li
              className={`${selectedValue === 5 ? "active" : ""}`}
              onClick={() => setSelectedValue(5)}
            >
              Transparency
            </li>
            <li
              className={`${selectedValue === 6 ? "active" : ""}`}
              onClick={() => setSelectedValue(6)}
            >
              Committed
            </li>
            <li
              className={`${selectedValue === 7 ? "active" : ""}`}
              onClick={() => setSelectedValue(7)}
            >
              Consistency
            </li>
          </ul>
          {selectedValue === 4 && <Image n={4} />}
          {selectedValue === 5 && <Image n={5} />}
          {selectedValue === 6 && <Image n={6} />}
          {selectedValue === 7 && <Image n={7} />}
          <br />
          <p>Mutual Benefit is The Goal, Customer benefit is The Priority.</p>
        </div>
        <div className="padded capabilities">
          <h1 className="heading capabilities">Capabilities</h1>
          <p>1. Flexo Label Printing</p>
          <ul>
            <li>Gallus 6 Colors</li>
            <li>Gallus 10 Colors</li>
          </ul>
          <p>2. Flexo Packaging Printing</p>
          <ul>
            <li>Omet X4 10 Colours</li>
          </ul>
          <p>3. Quality Control Machinery</p>
          <ul>
            <li>Prati 100% Inspection</li>
          </ul>
        </div>
        <div className="capabilities-image">
          <Image n={2} />
        </div>
        <div className="padded workplace">
          <h1 className="heading">Workplace</h1>
          <h2 className="subheading">
            Creating a working culture that is dynamic, supportive and safe
          </h2>
          <p className="description">
            While our machines are the heart of our operations, our workers are
            the soul. We invest only in experienced individuals to operate our
            machines and manage the process, while at the same time developing
            our workers’ knowledge and skills so that they are able to give you
            the highest quality work.
          </p>
        </div>
        <div className="workplace-image">
          <Image n={3} />
        </div>
        <div className="padded quality-control">
          <h1 className="heading">Quality Control</h1>
          <h2 className="subheading">ACCURATE</h2>
          <h3 className="description">Color Reproduction</h3>
          <div>
            <img src={accurateImage} />
          </div>
          <h2 className="subheading">PRECISE</h2>
          <h3 className="description">Quality Inspection</h3>
          <div>
            <img src={preciseImage} />
          </div>
        </div>
        <Services />
      </div>
    </StyledAbout>
  )
}

export default About
