import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledMission = styled.div`
  .mission-wrapper {
    display: grid;
    grid-template-columns: 1fr 4fr;
    margin-top: 6vw;
    .content-wrapper {
      display: grid;
      grid-template-columns: 8fr 2fr;
      .content {
        display: grid;
        grid-template-columns: 2fr 6fr;
        padding-left: 2rem;
        margin-bottom: 3vw;
        .mission-heading {
          font-size: 1.7vw;
          font-weight: 700;
          color: black;
        }
        .mission-subheading {
          h1 {
            color: black;
            font-weight: 400;
            font-size: 1.7vw;
            line-height: 2.5vw;
            margin-bottom: 3vw;
            .medium {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
`

const Mission = () => {
  return (
    <StyledMission>
      <div className="mission-wrapper">
        <div className="empty-space"></div>
        <div className="content-wrapper">
          <div className="content">
            <h1 className="mission-heading">MISSION</h1>
            <div className="mission-subheading">
              <h1>
                To provide labels of{" "}
                <span className="medium">
                  excellence quality for all of our customers needs.
                </span>
              </h1>
              <h1>
                To provide{" "}
                <span className="medium">
                  solutions for our customers through ; design inputs, on time
                  delivery, competitive pricing, and excellent customer service.
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </StyledMission>
  )
}

export default Mission
