import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledSplash = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  .right {
    position: relative;
    height: 85vh;
    background-color: grey;
    .black-box {
      display: flex;
      position: absolute;
      width: 70%;
      height: 30%;
      background-color: black;
      bottom: 0;
      color: white;
      padding: 0 2rem;
      font-size: 1.2vw;
      line-height: 2vw;
      align-items: center;
      p {
        margin: 0;
      }
    }
    .about-bmr-labels {
      position: absolute;
      top: 30%;
      left: -13%;

      h1 {
        font-size: 5vw;
        font-weight: 400;
        color: black;
      }
    }
  }
`

const BgImg = styled(Img)`
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: pointer;
  overflow: hidden;
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 50% 50% !important; // or whatever
  }
`

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "about-splash-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <BgImg fluid={data.coverImage.childImageSharp.fluid} />
}

const Splash = () => {
  return (
    <StyledSplash>
      <div className="left"></div>
      <div className="right">
        <Image />
        <div className="black-box">
          <p>
            Thank you for taking the time to read about us. We are a company
            specializing in printing labels for product packaging. If you are in
            the F&B, HPC, Pharmaceutical or any other industry that needs labels
            printed, there is definitely a way for us to help you with your
            labelling needs.{" "}
          </p>
        </div>
        <div className="about-bmr-labels">
          <h1>About Us</h1>
        </div>
      </div>
    </StyledSplash>
  )
}

export default Splash
