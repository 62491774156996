import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledCapabilities = styled.div`
  display: grid;
  padding-top: 6vw;
  padding-bottom: 8vw;
  grid-template-columns: 1fr 4fr;
  .empty-space {
    position: relative;
    .line {
      position: absolute;
      height: 1px;
      width: 18vw;
      background-color: lightgrey;
      top: 1.9vw;
    }
  }
  .container {
    padding-left: 2rem;
    display: grid;
    grid-template-columns: 3fr 5fr;
    display: relative;

    h1 {
      color: black;
      font-weight: 400;
      position: relative;
      font-size: 4vw;
    }
    .li-heading {
      font-family: "Akzidenz Grotesk Extended";
      font-weight: 600;
      color: black;
      transform: translateX(-0.3vw);
      margin-bottom: 1vw;
      font-size: 1.2vw;
    }
    ul {
      list-style-type: square;
      transform: translateX(0.8vw);
      font-size: 1.2vw;
    }
    .right {
      margin-top: 2vw;
    }
  }
`

const BgImg = styled(Img)`
  position: absolute;
  width: 100%;
  height: 45vh;
  z-index: 0;
  cursor: pointer;
  overflow: hidden;
  img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "about-capabilities.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <BgImg fluid={data.coverImage.childImageSharp.fluid} />
}

const Capabilities = () => {
  return (
    <StyledCapabilities>
      <div className="empty-space">
        <div className="line" />
      </div>
      <div className="container">
        <div className="left">
          <h1>Capabilities</h1>
          <p className="li-heading">1. Flexo Label Printing</p>
          <ul>
            <li>Gallus 6 Colours</li>
            <li>Gallus 10 Colours</li>
          </ul>
          <p className="li-heading">2. Flexo Packaging Printing</p>
          <ul>
            <li>Omet X4 10 Colours</li>
          </ul>
          <p className="li-heading">3. Quality Control Machinery</p>
          <ul>
            <li>Prati 100% Inspection</li>
          </ul>
        </div>
        <div className="right">
          <Images />
        </div>
      </div>
    </StyledCapabilities>
  )
}

export default Capabilities
