import React from "react"
import styled from "@emotion/styled"
import { useMediaQuery } from "react-responsive"

import AboutMobile from "./AboutMobile"
import Splash from "./Splash"
import Description from "./Description"
import Vision from "./Vision"
import Mission from "./Mission"
import Values from "./Values"
import Capabilities from "./Capabilities"
import Workplace from "./Workplace"
import QualityControl from "./QualityControl"
import Services from "./Services"

const StyledAbout = styled.div`
  min-height: 100vh;
  padding-top: 15vh;
`

const About = () => {
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 900px)",
  })
  return (
    <StyledAbout>
      {isDesktop && (
        <div>
          <Splash />
          <Description />
          <Vision />
          <Mission />
          <Values />
          <Capabilities />
          <Workplace />
          <QualityControl />
          <Services />
        </div>
      )}
      {!isDesktop && (
        <div>
          <AboutMobile />
        </div>
      )}
    </StyledAbout>
  )
}

export default About
