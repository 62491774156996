import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledWorkplace = styled.div`
  display: grid;
  padding-top: 6vw;
  grid-template-columns: 1fr 4fr;
  background-color: lightgrey;
  margin-top: 5vw;
  .empty-space {
    position: relative;
    .image-wrapper {
      width: 100%;
      position: absolute;
      bottom: 0;
      right: -50%;
    }
  }
  .container {
    padding-left: 2rem;
    display: grid;
    grid-template-columns: 3fr 5fr;
    display: relative;
    margin-bottom: 5vw;
    .right {
      margin-top: 2vw;
      h1 {
        color: black;
        font-weight: 400;
        position: relative;
        font-size: 4.5vw;
        margin-bottom: 3vw;
      }
      .li-heading {
        font-family: "Akzidenz Grotesk Extended";
        font-weight: 600;
        color: black;
        margin-bottom: 1vw;
        font-size: 1.7vw;
        line-height: 2vw;
        margin-bottom: 3vw;
      }
      p {
        max-width: 70%;
        font-size: 1.2vw;
        color: #636363;
      }
    }
  }
`

const BgImg = styled(Img)`
  position: absolute;
  width: 35vw;
  height: 45vw;
  z-index: 0;
  cursor: pointer;
  img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "about-workplace.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <BgImg fluid={data.coverImage.childImageSharp.fluid} />
}

const Workplace = () => {
  return (
    <StyledWorkplace>
      <div className="empty-space">
        <div className="image-wrapper">
          <Image />
        </div>
      </div>
      <div className="container">
        <div className="left"></div>
        <div className="right">
          <h1>Workplace</h1>
          <p className="li-heading">
            Creating a working culture that is dynamic, supportive and safe.
          </p>
          <p>
            While our machines are the heart of our operations, our workers are
            the soul. We invest only in experienced individuals to operate our
            machines and manage the process, while at the same time developing
            our workers’ knowledge and skills so that they are able to give you
            the highest quality work.
          </p>
        </div>
      </div>
    </StyledWorkplace>
  )
}

export default Workplace
