import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledValues = styled.div`
  background-color: black;
  color: white;
  display: grid;
  padding-top: 5vw;
  padding-bottom: 5vw;
  grid-template-columns: 1fr 4fr;
  .content {
    padding-left: 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr;
    .left {
      .heading {
        font-size: 4vw;
        font-weight: 400;
        margin-bottom: 5vw;
      }
      .list {
        .list-item {
          p {
            display: inline-block;
            font-size: 1.5vw;
            margin-bottom: 3.5vw;
            font-family: "Akzidenz Grotesk Extended";
            cursor: pointer;
          }
          .selected {
            transition: 0.1s;
            font-weight: bold;
            border-bottom: 1px solid white;
          }
        }
      }
    }
    .right {
      padding-top: 4vw;
      padding-right: 5vw;
      .basic {
        font-size: 1.2vw;
      }
      .images {
        height: 24vw;
        background-color: deeppink;
        margin-bottom: 3vw;
      }
      h1 {
        font-weight: 400;
        font-size: 1.5vw;
        .medium {
          font-weight: 600;
        }
      }
    }
  }
`

const BgImg = styled(Img)`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: pointer;
  overflow: hidden;
  img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const Values = () => {
  const [selected, setSelected] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      honestyImage: file(relativePath: { eq: "values/values-honesty.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transparencyImage: file(
        relativePath: { eq: "values/values-transparency.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      committedImage: file(
        relativePath: { eq: "values/values-committed.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      consistencyImage: file(
        relativePath: { eq: "values/values-consistency.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledValues selected={selected}>
      <div></div>
      <div className="content">
        <div className="left">
          <h1 className="heading">Values</h1>
          <ul className="list">
            <li className="list-item">
              <p
                className={selected === 0 ? "selected" : ""}
                onClick={() => setSelected(0)}
              >
                Honesty
              </p>
            </li>
            <li className="list-item">
              <p
                className={selected === 1 ? "selected" : ""}
                onClick={() => setSelected(1)}
              >
                Transparency
              </p>
            </li>
            <li className="list-item">
              <p
                className={selected === 2 ? "selected" : ""}
                onClick={() => setSelected(2)}
              >
                Committed
              </p>
            </li>
            <li className="list-item">
              <p
                className={selected === 3 ? "selected" : ""}
                onClick={() => setSelected(3)}
              >
                Consistency
              </p>
            </li>
          </ul>
        </div>
        <div className="right">
          <p className="basic">
            Our basic and fundamental principles in Business:
          </p>
          <div className="images">
            {selected === 0 && (
              <BgImg fluid={data.honestyImage.childImageSharp.fluid} />
            )}
            {selected === 1 && (
              <BgImg fluid={data.transparencyImage.childImageSharp.fluid} />
            )}
            {selected === 2 && (
              <BgImg fluid={data.committedImage.childImageSharp.fluid} />
            )}
            {selected === 3 && (
              <BgImg fluid={data.consistencyImage.childImageSharp.fluid} />
            )}
          </div>
          <h1>
            Mutual Benefit is <span className="medium">The Goal</span>, Customer
            benefit is <span className="medium">The Priority.</span>
          </h1>
        </div>
      </div>
    </StyledValues>
  )
}

export default Values
