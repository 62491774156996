import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import cameraInspection from "../../../images/quality-control/camera-inspection.svg"
import colorManagementSystem from "../../../images/quality-control/color-management-system.svg"
import digitalProofingSystem from "../../../images/quality-control/digital-proofing-system.svg"
import slittingMachine from "../../../images/quality-control/slitting-machine.svg"
import withImage from "../../../images/quality-control/with.svg"

const StyledQualityControl = styled.div`
  padding-top: 6vw;
  padding-bottom: 10vw;
  padding-left: 8vw;
  padding-right: 8vw;
  .heading-wrapper {
    text-align: center;
    margin-bottom: 5vw;
    .heading {
      font-weight: 400;
      margin: 0 auto;
      font-size: 4.5vw;
      color: black;
    }
  }
  .quality-control-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15vw;
    .grid-item {
      text-align: center;
      .heading {
        font-size: 1.5vw;
        font-family: "Akzidenz Grotesk Extended";
        margin-bottom: 0.7vw;
        color: black;
      }
      .subheading {
        font-size: 1.5vw;
        font-weight: 600;
        font-family: "Akzidenz Grotesk Extended";
        color: black;
        margin-bottom: 2vw;
      }
      .accurate-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1vw;
        align-items: center;
        .image-wrapper {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          .digital-proofing-system {
            height: 10vw;
          }
          .color-management-system {
            width: 100%;
            max-height: 7.5vw;
            transform: translateY(0.6vw) translateX(-0.5vw);
          }
          .caption {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) translateY(100%);
            width: 400px;
          }
        }
      }
      .precise-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1vw;
        align-items: center;
        .image-wrapper {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          .slitting-machine {
            height: 8vw;
          }
          .camera-inspection {
            width: 100%;
            max-height: 10vw;
            transform: translateY(0.6vw) translateX(-0.5vw);
          }
          .caption {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) translateY(100%);
            width: 400px;
          }
        }
      }
    }
  }
`

const QualityControl = () => {
  return (
    <StyledQualityControl>
      <div className="heading-wrapper">
        <h1 className="heading">Quality Control</h1>
      </div>
      <div className="quality-control-content">
        <div className="left grid-item">
          <div className="heading">ACCURATE</div>
          <div className="subheading">Color Reproduction</div>
          <div className="accurate-content">
            <div className="image-wrapper">
              <img
                className="digital-proofing-system"
                src={digitalProofingSystem}
              ></img>
              <div className="caption">
                DIGITAL PROOFING
                <br />
                SYSTEM
              </div>
            </div>
            <div className="image-wrapper">
              <img className="with" src={withImage} alt="with arrow"></img>
            </div>
            <div className="image-wrapper">
              <img
                className="color-management-system"
                src={colorManagementSystem}
                alt="Color Management System"
              ></img>
              <div className="caption">
                COLOR MANAGEMENT<br></br>SYSTEM
              </div>
            </div>
          </div>
        </div>
        <div className="right grid-item">
          <div className="heading">PRECISE</div>
          <div className="subheading">Quality Inspection</div>
          <div className="precise-content">
            <div className="image-wrapper">
              <img
                className="slitting-machine"
                src={slittingMachine}
                alt="Slitting Machine"
              ></img>
              <div className="caption">
                SLITTING MACHINE
                <br />
                <i>250m/min</i>
              </div>
            </div>
            <div className="image-wrapper">
              <img className="with" src={withImage} alt="with arrow"></img>
            </div>
            <div className="image-wrapper">
              <img
                className="camera-inspection"
                src={cameraInspection}
                alt="Camera Inspection"
              ></img>
              <div className="caption">
                COLOR MANAGEMENT
                <br />
                <i>100% accuracy</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledQualityControl>
  )
}

export default QualityControl
