import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/About"

const AboutPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="About" keywords={[`BMR`, `labels`]} />
    <About />
  </Layout>
)

export default AboutPage
